<template>
  <ListRmaTable :columns="columns" />
</template>
<script>
  import {
    defineComponent,
    computed,
    onMounted
  } from "vue";
  import { useI18n } from 'vue-i18n'
  import ListRmaTable from '@/components/listRma'
  import ListRmaState from '@/state/ListRma'

  export default defineComponent({
    components: {
      ListRmaTable
    },
    setup() {
      const { t ,locale } = useI18n({ useScope: 'global' })
      const { state } = ListRmaState()
      const columns = computed(() => {
        const filtered = state.filteredInfo || {};
        const sorted = state.sortedInfo || {};
        const filters = state.statusList || [];
        return[
          {
            title: t('listRMA.sku'),
            key: "img",
            dataIndex: "SKU",
            slots: {
              customRender: "img",
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
            },
            onFilter: (value, record) => {
              return record.product_sku.toString().toLowerCase().includes(value.toLowerCase())
            },
          },
          {
            title: t('route.RMA'),
            key: "rma_id",
            dataIndex: "RmaID",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
              customRender: "rmaNumber",
            },
            onFilter: (value, record) => {
              return record.RmaID.toString().toLowerCase().includes(value.toLowerCase())
            },
            sorter: (a, b) => {
              // return a.RmaID.localeCompare(b.RmaID)
            },
            sortOrder: sorted.columnKey === 'rma_id' && sorted.order,
          },
          {
            title: t('user.name'),
            dataIndex: "fullname",
            key: "name",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) =>
              record.fullname.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
              // return a.fullname.localeCompare(b.fullname)
            },
            sortOrder: sorted.columnKey === 'name' && sorted.order,
          },
          {
            title: t('route.StoreID'),
            dataIndex: "store_id",
            key: "store_id",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) =>
              record.store_id.toString().toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: t('route.StoreName'),
            dataIndex: "created_by",
            key: "created_by",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) =>
              record.created_by.toString().toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: t('user.email'),
            dataIndex: "email",
            key: "email",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
              // customRender: "customRender",
            },
            onFilter: (value, record) =>
              record.email.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
              // return a.email.localeCompare(b.email)
            },
            sortOrder: sorted.columnKey === 'email' && sorted.order,
          },
          {
            title: t('route.Phone'),
            dataIndex: "fullphone",
            key: "phone",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
              customRender: "phone",
            },
            onFilter: (value, record) =>
              record.phone.toString().toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: t('route.PurchaseDate'),
            dataIndex: "date_of_purchase",
            key: "date_of_purchase",
            slots: {
              filterDropdown: "filterDropdownDate",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) => {
              return record
            },
            sorter: (a, b) => {
              // return a.date_of_purchase.localeCompare(b.date_of_purchase)
            },
            sortOrder: sorted.columnKey === 'date_of_purchase' && sorted.order,
          },
          {
            title: t('route.WarrantyType'),
            dataIndex: "is_under_warranty",
            key: "is_under_warranty",
            slots: {
              customRender: "isUnderWarranty",
            },
            filters: [
              {
                text: 'YES',
                value: 1
              },
              {
                text: 'NO',
                value: 0
              }
            ],
            filteredValue: filtered.is_under_warranty || null,
            onFilter: (value, record) => {
              return record
            }
          },
          {
            title: t('route.CreateDate'),
            dataIndex: "received_date",
            key: "receive_item_date",
            slots: {
              filterDropdown: "filterDropdownDate",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) => {
              return record
              if (record.received_date) {
                return record.received_date.toString().toLowerCase().includes(value.toLowerCase())
              }
            },
            sorter: (a, b) => {
              // return a.received_date.localeCompare(b.received_date)
            },
            sortOrder: sorted.columnKey === 'receive_item_date' && sorted.order,
          },
          {
            title: t('route.Status'),
            key: "RmaStatus",
            // dataIndex: "RmaStatus.Name",
            dataIndex: "RmaStatus",
            slots: {
              customRender: "status",
            },
            filters,
            filteredValue: filtered.RmaStatus || null,
            onFilter: (value, record) => {
              return record.RmaStatus.Name.toString().toLowerCase().includes(JSON.parse(value).label.toLowerCase())
            }
          },
          {
            title: t("route.Worker"),
            key: "Worker",
            dataIndex: "Worker",
            slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: "worker",
            },
            onFilter: (value, record) => {
              if (record.RmaStatusHistory && record.RmaStatusHistory.length > 0) {
                return record.RmaStatusHistory[0].CreatedBy.toString().toLowerCase().includes(value.toLowerCase())
              }
            }  
          },
          {
            title: t("route.WaivedFee"),
            key: "waived_fee_status",
            dataIndex: "waived_fee_status",
            slots: {
              customRender: "WaivedFeeStatus",
            }
          },
          {
            title: t('route.RepairTime'),
            dataIndex: 'repair_history.total_180',
            key: 'repair_history.total_180',
            align: 'center',
            slots: {
              customRender: "repairTimes"
            },
          },
        ];
      });

      onMounted(() => {
        state.searchData = {}
      })

      return {
        columns
      };
    }
  });
</script>

<style scoped lang="scss">
</style>